@import './colors';
@import './forms';
@import './mixings';
@import './modals';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400&display=swap');

body {
    overflow-y: scroll;
    font-family: roboto !important;
}
::-webkit-scrollbar-track {
    background-color: #F4F4F4;
  }
  ::-webkit-scrollbar {

    width: 10px;
    background: #F4F4F4;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #dad7d7;
  }

.form-control.is-valid, .was-validated .form-control:valid {
    background-image: none;
}
.show > .dropdown-menu {
    max-height: 900px;
    visibility: visible;
  }

.dropdown-menu {
   border-radius: 5px !important;
   border-color: rgb(207, 203, 203) !important;
   display: block;
  max-height: 0;
  visibility: hidden;
  transition: all 0.4s ease-in-out;
  overflow: hidden;
  }

.btn{
    border-radius: 10px !important;
    font-weight: 500;
}
.Container{
    max-width: 1400px;
}
.table thead th {
    font-weight: 500;
   vertical-align: middle;
   background-color: $color-2;
   color: white;

}
.table-responsive {
    border-radius: 10px;

}
.row{
    margin-right: 0px;
    margin-left: 0px;
}
.modal .modal-dialog .modal-content{
  border-radius: 10px !important;
}
.modal-header{
  border-bottom: none;
}
.modal-footer{
  border-top: none;
  padding: 0 1rem;
}
.table thead th {
  border-bottom: none !important;
}
