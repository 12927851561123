@import "./../../styles/global.scss";

.CopyLink {
    padding-top: 10%;

    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;

    color: #4274e3;
    cursor: pointer;
}

.boxtoken{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    align-content: center;
}

.token{
    border-width: 1;
    border-style: solid;
    border-color: #4274e3;
}


