@import './../../styles/global.scss';


.buttonElement{
    display:flex;
    font-weight: bold;

}

.iconButton {
    margin-right: 8px;
}
