@import "./../../styles/global.scss";

.Clients {
  .row {
    width: 100%;
    padding: 30px 15px 0;
  }
  h2 {
    color: $color-3;
    text-align: left;
  }
  .button {
    button {
      color: #ffffff;
      background-color: $color-2;
      border-radius: 0;
      float: right;
      a {
        color: #ffffff;
      }
      &:hover {
        background-color: $color-1 !important;
        border-color: $color-1 !important;
        a {
          color: #ffffff;
          text-decoration: none;
        }
      }
    }
  }
  .table {
    img.showProfileImage {
      border: 2px solid #32a1ee;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin-right: 10px;
    }
    thead {
      padding: 15px;
      color: #ffffff;
    }
    tr:nth-child(odd) > td {
      background-color: #ffffff !important;
    }
    tr:nth-child(even) > td {
      background-color: #ecf7fd;
    }
  }
}
