.filter{
  display: flex;
  align-items: center;
  justify-content: space-between;
  svg{
    margin-left: 10px;
  }
}
.dropMenu{
  margin: 0 !important;
  padding: 20px !important;
  width: max-content;

}
.selectLimit{
  width: 100% !important;
}
