
.dropMenuClients{
  margin: 0 !important;
  width: max-content !important;
  padding:5px 10px 5px 0 !important;
}
.filterLabel{
  color: #4274e3;
  width: max-content !important;
}
