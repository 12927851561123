@import "./../../../styles/global.scss";

.SeeUsers {
    .col{
        min-width: 100%;
    }

    height: 90vh;
    @include flexbox(column, center, center);
    .title{
        color: $color-2;
        text-align: left;
        width: 100%;
        padding: 0 0 35px 15px;
    }
    min-width: 300px;
    max-width: 600px;
    form{
        width: 100%;
        button{
            width: 100%;
        }
    }


.selectmenu{
    margin-top: 7px;
    color: #545b7b;
    background-color: white;
    text-decoration: none;
}
}
