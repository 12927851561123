@import "./../../../styles/global.scss";

.footer-container {
  font-size: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
  background-color: #32a1ee;
  bottom: 0;
  color: white;
  padding: 5px;
  .footer p {
    margin-top: 10px;
    text-align: center;
  }

  .footer div {
    text-align: center;

  }

  .footer a {
    text-decoration: none;
    margin:0 5px;
    border-bottom: 1px solid white;
    color: white;
  }
}
