@import "./../../../styles/global.scss";

.UpdateUsers {
    margin-top: 20px;
    .col{
        min-width: 100%;
    }
    height: 100%;
    @include flexbox(column, center, center);
    .title{
        color: $color-2;
        text-align: left;
        width: 100%;
        padding: 0 0 0px 15px;
        align-items: center;
        display: flex;
        .profileImage {
          display: flex;
          width: 100%;
          justify-content: flex-end;
          margin: 0 50px 0 0;
        }
    }
    min-width: 300px;
    max-width: 600px;
    form{
        width: 100%;
        button{
            width: 100%;
        }
    }
    .resetButton{
        width: 50%;

    }

}

.selectmenu{
    margin-top: 7px;
    color: #545b7b;
    background-color: white;
    text-decoration: none;
}
.teste{
    padding-left: 13px;
}
