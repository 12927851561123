@import "./../../styles/global.scss";

.Notes {
  .row {
    width: 100%;
    padding: 30px 15px 0;

    > .filterCol {
      max-width: 150px;
      > .filterSelect {
        max-width: 150px;
      }
    }

    > .searchFilterCol {
      margin-right: auto;
    }
    > .perPage {
      > .labelLimit {
        display: block;
        text-align: right;
        white-space: nowrap;
        // margin-right: 10px;
      }
      > .selectLimit {
        margin-left: auto;
        max-width: 124px;
      }
    }
  }

  h2 {
    color: $color-3;
    text-align: left;
  }

  .table {
    thead {
      padding: 15px;
      background-color: $color-1;
      color: #ffffff;
    }
    tr {
      cursor: pointer;
      transition: 180ms all;
      &:hover {
        filter: brightness(0.98);
      }
    }
    tr:nth-child(odd) > td {
      background-color: #ffffff !important;
    }
    tr:nth-child(even) > td {
      background-color: #ecf7fd;
    }

    td {
      padding: 4 12px;
    }

    .tableEmpresa {
      width: 20%;
    }

    .tableOptions {
      width: 12%;
    }
  }
}

.percent {
  bottom: -2px;
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  text-align: center;
  width: 100%;
}
