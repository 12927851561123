@import './../../styles/global.scss';

.titleNewCertify {
    margin-left: 5px;
    font-size: 24px;
}

.subtitleNewCertify {
    margin-left: 7px;
    font-size: 14px;
    color: #999;
}

.titleInput {
    margin-left: 8px;
}

.uploadFile {
    width: 100%;
}
.buttonElement{
    margin-right: 15px;
}

.uploadArea {

    svg {
        margin-bottom: 16px;
    }

    .uploadMsgUpload {
        color: #4274e3;
    }

    .uploadMsgOpacity, .fileName {
        color: #999;
    }

    align-items: center;
    display: flex;
    flex-direction: column;
    min-height: 100px;
    justify-content: space-evenly;
    padding: 16px 0;
    width: 100%;
}

.rowCertify:nth-child(odd) {
    background: #ffffff !important
}

.rowCertify:nth-child(even) {
    background: #ecf7fd !important
}


.iconsOptions {
    display: flex;
    justify-content: flex-start;
}

.modalHeader{
    display: flex;
    align-items: center;
}

.iconHeader {
    margin-left: 10px;
    margin-top: 6px;
    align-self: flex-start;
}

.btnsCertificate {
    margin-bottom: 0;
    width: 90px;
}

// .buttonElement {
//     border-radius: 5px !important;
// }

.iconButton {
    margin-top: 0px !important;
}
