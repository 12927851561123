@import "./../../styles/global.scss";

.Clients {
  width: 100%;
  min-height: calc(100vh - 150px);
  padding: 0 30px;
  margin: 10px 0;
  display: flex;
  flex-direction: column;



  .row {
    width: 100%;

  }
  h2 {
    color: $color-2 !important;
    text-align: left;
  }
  .button {
    button {
      font-weight: 500;
      color: #ffffff;
      background-color: #007bff !important;
      border-radius: 0;
      float: right;
      a {
        color: #ffffff;
      }
      &:hover {
        background-color: $color-1 !important;
        border-color: $color-1 !important;
        a {
          color: #ffffff;
          text-decoration: none;
        }
      }
    }
  }
  .ButtonOptions{
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
  .table {
    border-radius: 10px;
    -webkit-box-shadow: 0px -2px 24px -4px rgba(0,0,0,0.47);
    box-shadow: 0px -2px 24px -4px rgba(0,0,0,0.47);
    thead th {
      padding:15px;
      background-color: white !important;
      color: $color-2 !important;
    }
    tr:nth-child(odd) > td {

      background-color: #ffffff !important;
    }
    tr:nth-child(even) > td {

      background-color: #ecf7fd;
    }
    tr:nth-of-type(odd):hover td{

      background-color: rgb(200, 228, 243) !important;
    }
    tr:nth-of-type(even):hover td{

      background-color: rgb(200, 228, 243)!important;
    }
    td {
      padding: 4 12px;
    }

    .tableEmpresa {
      width: 20%;
    }

    .tableOptions {
      width: 12%;
    }
  }

}

.percent {
  bottom: -2px;
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  text-align: center;
  width: 100%;
}

@media(max-width: 768px) {
  .Clients{
    padding: 0;
  }
}
