@import "./../../styles/global.scss";
.Dashboard{
    .row {
        width: 100%;
        padding-top: 30px;
      }
      .col {
            padding-right: 0px;
            padding-left: 0px;
            min-width: 300px;
      }
      h2 {
        color: $color-3;
        text-align: left;
        padding-left: 15px;
      }
      .bodyColHead{

        background-color: $color-1;

      }
      .title h3{
          text-align: center;
          color: white;
          padding: 2px 0;
      }

      .bodyCol{
          min-height: 300px;
          overflow-y: auto;
          max-height: 300px;
          border-radius: 10px;
          margin: 10px;
          -webkit-box-shadow: 0px -2px 24px -4px rgba(0,0,0,0.47);
            box-shadow: 0px -2px 24px -4px rgba(0,0,0,0.47);
      }
      .bodyItem{
        display: flex;
        margin: 10px 0;
        justify-content: space-between;
        padding: 0 10px;

      }
      .bodyItem p{
          text-align: start;
      }
      .bodyItem:hover{
          background-color: rgb(224, 224, 224);
      }
      .loading{
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc( 100% - 50px)
      }
}
.ContainerDash{
  width: 100%;
  height: auto;
  display: flex;
  margin: 10px 0;



}

@media(max-width: 768px){
  .ContainerDash{
  flex-direction: column;
  }

}
