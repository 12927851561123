@import './../../../styles/global.scss';

.selectmenu{
    margin-top: 7px;
    color: #545b7b;
    text-decoration: none;
}

.selectmenu{
    margin-top: 7px;
    color: #545b7b;
    background-color: white;
    text-decoration: none;
}
.teste{
    padding-left: 5px;
}