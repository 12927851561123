@import '../../styles/global.scss';

.imageClient {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  border: 2px solid #4274e3;
  border-radius: 50%;
  background-image: url('../../assets/img/profileImage.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 200px;
  height: 200px;
  margin: auto auto 20px auto;
  box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.25);
  .overlay-clients {
    position: relative;
    bottom: -10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: .5s ease;
  }
  #btnEdit {
    display: none;
    padding-bottom: 20px;
  }
  input[type='file'] {
    display: none;
  }
}
:hover.custom-file-upload {
  cursor: pointer;
}
.imageClient:hover .overlay-clients {
  height: 40%;
}
.line {
  width: 40%;
  border: 1px solid #4274e3;
  margin: 30px auto;
}
