form{
    .form-control[readonly]{
        background-color: #f5f5f5;
        opacity: 1;
    }
    label{
        font-size: 16px;
        color: $color-2;
        margin-bottom: 0;
    }

    input{
        color: $color-3 !important;
        border-radius: 0 !important;
        border-color: $color-3 !important;
        border-top: 0 !important;
        border-left: 0 !important;
        border-right: 0 !important;
        padding-left: 0 !important;
    }

    button{
        color: #ffffff !important;
        // background-color: $color-2  !important;
        margin: 30px 0 20px  !important;
        border-radius: 0 !important;
        a{
            color: #ffffff;
        }
        // &:hover{
        //     background-color: $color-1  !important;
        //     border-color: $color-1  !important;
        //     a{
        //         color: #ffffff;
        //         text-decoration: none;
        //     }
        // }
    }

    .link{
        color:  $color-3 !important;
        text-align: center !important;
    }
}

.form-control:focus{
    box-shadow: none !important;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color:  $color-3;
    opacity: 1; /* Firefox */
    font-size: 15px;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color:  $color-3;
    font-size: 15px;
}

::-ms-input-placeholder { /* Microsoft Edge */
    color:  $color-3;
    font-size: 15px;
}
