.filter{
  background-color: white !important;
  border: none;
  padding: 5px 10px;
  color: #4274e3;
  font-weight: 500;
  border-radius:10px ;
}
.filter:hover{

  background-color: #ecf7fd !important;
}
.filter:focus{
  border: none !important;
  outline: none;
  background-color: #ecf7fd !important;
}

