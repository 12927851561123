@import './../../../styles/global.scss';

.NewClients {
    .row{
        width: 100%;
    }
    .title{
        padding: 30px 15px;
    }
    h2{
        color: $color-2;
        text-align: left;
        color: $color-3;
        padding-left: 15px;
    }
    h3{
        color: $color-2;
        font-size: 1.5rem;
        padding-bottom: 20px;
        margin-bottom: 0;
    }
    form{
        width: 100%;
        padding: 0 15px;
        .form-block{
            padding-bottom: 30px;
            .form-row{
                padding-bottom: 10px;
                .form-group{
                    padding: 0 7px;
                }
                button{
                    float: right;
                    margin: 0 !important;
                }
            }
        }
    }

    table{
        margin-bottom: 0;
        color: $color-1;
        thead{
            color: $color-1;
        }
        button{
            float: right;
            margin: 0 !important;
        }
    }

    .button {
        button{
            color: #ffffff;
            background-color: $color-2;
            float: right;
            a{
                color: #ffffff;
            }
            &:hover{
                background-color: $color-1  !important;
                a{
                    color: #ffffff;
                    text-decoration: none;
                }
            }
        }
    }
    .subimit-btn{
        width: 100%;
    }
}

.tokenstatusbox{
    width:200px;
    height:40px;
    display:grid;
    grid-template-rows: auto;
}

.status{
    display:grid;
    grid-template-columns: 10% 90%;
    align-items: center;
    justify-content: center;
}
.ButtonOptions{
    display: flex;
    align-items: center;
    margin-left: 5px;
  }
