.modal{
    .modal-dialog{
       .modal-content{
           border-radius: 0 !important;
            .modal-header{
                color: $color-2 !important;
            }
            .modal-body{
                .modal-row{
                    padding: 15px 10px !important;
                    margin: 0 !important;
                    border-top: none !important;
                    border-bottom: 1px solid #dfdfdf !important;
                    border-left: 1px solid #dfdfdf !important;
                    border-right: 1px solid #dfdfdf !important;
                    border-radius: 0 !important;
                    &:first-child{
                        border-top: 1px solid #dfdfdf !important;
                    }
                    &__line{
                        @include flexbox(row, flex-start, center);
                    }
                }
                h5{
                    display: block;
                    padding-bottom: 30px;
                }
                .progress{
                    margin: 15px 0 5px 0;
                }
            }
            .modal-footer{
                button{
                    margin: 30px 0 20px  !important;
                    border-radius: 10px !important;
                }
                .btn-primary{
                    color: #ffffff !important;
                    //background-color: $color-2  !important;
                    margin-left: 5px !important;
                    a{
                        color: #ffffff;
                    }
                    &:hover{
                        //background-color: $color-1  !important;
                        border-color: $color-1  !important;
                        a{
                            color: #ffffff;
                            text-decoration: none;
                        }
                    }
                }
            }
       }

    }
}
