@import "./../../../styles/global.scss";

.FeedbackContainer{
position: fixed;
bottom: 0;
right: 0;
margin: 20px;

.feedButton{
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100% !important;

}
.feedButton:focus{
  outline: none;
  box-shadow: none;
}
}

.feedbackOverlay{
  padding: 15px;
  -webkit-box-shadow: 0px -2px 24px -4px rgba(0,0,0,0.47);
  box-shadow: 0px -2px 24px -4px rgba(0,0,0,0.47);
  border-radius: 10px;
  background-color:white;
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 20px;
  width: 336px;
  height: 264px;
  h3{
    font-size: 1.5rem;

    color: $color-2;
  }

}
.feedbackOverlayContent{

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  margin: 10px 0;

}
.feedbackOverlayContentItem{
  width: 96px;
  height: 112px;
  border-radius: 8px;
  padding: 8px;
  outline: none;
  border: none;
  margin: 15px 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: #ecf7fd ;
}
.feedbackOverlayContentItem:focus{
  outline: none;
  border: 2px solid $color-2;
}
.feedbackOverlayContentItem p{
  margin: 10px 0;
}

.feedbackOverlayContentFooter h4{
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: $color-2;
  a{
    color: $color-2;
  }
  }

.feedbackFormContainer{
  display: flex;
  flex-direction: column;
  margin: 25px 8px 5px;

}


.feedbackForm{
   width: 100%;
   min-height: 112px;
   max-height: 112px;
}
.feedbackSuccessContainer{

   display: flex;
   align-items: center;
  flex-direction: column;
  margin: 25px 8px 5px;

}

.feedbackSuccessContent{

  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 25px;

}
