@import './../../styles/global.scss';


.iconButton {
    display: flex;
    margin-top: auto;

    > button {
        background-color: #32a1ee;
    }
}
.buttonElement{
    display:flex;
    font-weight: bold;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.iconButton {
    // margin-right: 8px;
}