@import '../../../styles/global.scss';

.imageProfileUpdate {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-image: url('../../../assets/img/profileImage.png');
  background-size: cover;
  background-position: center;
  .overlay {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: .5s ease;
  }
  .btnEdit {
    display: none;
    padding-bottom: 10px;
  }
  input[type='file'] {
    display: none;
  }
}
:hover.custom-file-upload {
    cursor: pointer;
}
.imageProfileUpdate:hover .overlay {
  height: 40%;
}