@import "./../../styles/global.scss";

.Login {

  height: 90vh;
  @include flexbox(column, center, center);
  .title {
    color: $color-2;
    text-align: left;
    width: 100%;
    padding-bottom: 35px;
  }
  max-width: 400px;
  form {
    width: 100%;
    button {
      width: 100%;
    }
  }
}
