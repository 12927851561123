@import "./../../../styles/global.scss";
.ContainerDashNfe{
  width: 100%;

  overflow-y: auto;
  padding: 15px;
  border-radius: 10px;
  margin: 10px;
  -webkit-box-shadow: 0px -2px 24px -4px rgba(0,0,0,0.47);
    box-shadow: 0px -2px 24px -4px rgba(0,0,0,0.47);

  .bodyColHead{
    color: $color-2;
    display: flex;
    justify-content: space-between
  }

  .table thead th{

     background-color: white !important;
     color: $color-2 !important;

  }
  tbody tr{
    cursor: pointer;
  }
  tr:nth-child(odd) > td {

    background-color: #ffffff !important;
  }
  tr:nth-child(even) > td {

    background-color: #ecf7fd;
  }
  tr:nth-of-type(odd):hover td{

    background-color: rgb(200, 228, 243) !important;
  }
  tr:nth-of-type(even):hover td{

    background-color: rgb(200, 228, 243)!important;
  }
}
@media(max-width: 768px){
  .ContainerDashNfe{

    margin: 0;
  }
}
