@import "./../../../styles/global.scss";

.Header {
  @include flexbox(row, space-between, center);
  color: #fff;
  background-color: $color-1;
  width: 100%;
  height: auto;

  .Logo {

    img {
      width: 120px;
    }
  }
  .LogoWithoutLogin {
    text-align: center;
    margin: 0 auto;
    img {
      width: 120px;
    }
  }
  .header-menu {
    color: #fff;
    a {
      color: #fff;
      &:hover {
        color: $color-3;
        text-decoration: none;
      }
    }



  }
  .Container{
    max-width: 100%;
  }
  .menuDropDown{
    display: none
  }
  .right-menu {
    @include flexbox(row, flex-end, center);
    text-align: right;



  }

.profileNav {
  display: flex;
  align-items: center;
  .imageProfile {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    border-radius: 50%;
    background-image: url('../../../assets/img/profileImage.png');
    background-size: cover;
    background-position: center;
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
}

  .name{
    margin-right: 10px;
  }
  .left-menu {
    @include flexbox(row, flex-start, center);
    text-align: left;
  }
  .iconMenu{
    align-self: center;
  }
  .iconMenu button {
    background: rgba(0,0,0,0);
    border: none;
    margin-top: 14px;
  }

  a:hover{ text-decoration:none; }

  .iconMenu .dropdown-toggle::after {
    display: none;
  }
.dropdown{
  display: none;
}


}

.logout{
  margin-left: 10px;
  color: #4274e3 ;
  background-color: white;
  padding: 5px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.sideMenu:hover{
  margin-left: 10px;
  color: #4274e3 ;
  background-color: white;
  padding: 5px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}


.nav{
  display: flex;
  align-items:center ;
  width: max-content;
}
.navBar{
  display: none !important;
}
.navbar{
  max-height: 50px;
}
.navLink{
  display: flex;
  align-items: center;
  color: white !important;
  padding: 5px 10px;
  margin: 0 5px;
  width: max-content;
  text-decoration: none !important;
}
.navLink.active{
  color: #4274e3 !important;
  background-color: white !important;
  border-radius: 10px;
}
.navLink:hover{
  color: #4274e3 !important;
  background-color: white !important;
  border-radius: 10px;
}

@media(max-width:560px){
  .Header .Logo{
    display: flex;
    align-items: center;
    img {
      width: 120px;
    }
  }

  .name{
    display: none;

  }

}

@media only screen and (max-width: 650px){
  .Header .right-menu{
    display: none;
    flex-direction: row;
    justify-content: center;
  }

  .navLink{
    margin: 5px 5px;
  }

  .dropdown{
    display: flex !important;
    position: inherit;
    
    cursor: pointer;
    button {
      color: white !important;
      background-color: #32a1ee !important;
      border: none !important;
    }

  }

  .Header   .middleMenu{
    display: none !important;
  }

  .navbar{
    flex-direction: column;
    justify-content: center;
  }

  .rowContainer{
    align-items: center;
  }

}

.dropdown-toggle::after {
  display: none;
  
}

.DropdownMenu{
  inset: 23px auto auto 0px !important;
  margin: 0px !important;
  width: 100% !important;
  display: grid !important;
  place-items: center !important;
}
