@import './../../../styles/global.scss';

.corMenu{
    color: #545b7b;
}

.titlefrom{
    display: flex;
    align-items: flex-start;
    align-content: center;
}

.selectmenu{
    margin-top: 7px;
    color: #545b7b;
    background-color: white;
    text-decoration: none;
}
.titlefromdropdraw{
    padding-left: 7px;
}
.selectmenudois{
    margin-left: 9px;
}
.registerCompanyButton {
    width: 100%;
    display: flex;
    justify-content: center;
}